<!--
 * @Author: your name
 * @Date: 2022-03-04 10:52:57
 * @LastEditTime: 2022-03-04 17:02:41
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vi\src\components\DateTiem.vue
-->
<template>
  <div class="date-box">
    <van-calendar
      title="Chọn ngày"
      subtitle="xxxxxx"
      :poppable="false"
      :show-confirm="false"
      :show-subtitle="false"
      :show-title="true"
      bottomInfo="asdasdasdasda"
      :style="{ height: '450px' }"
      :min-date="minDate"
      @confirm="sureDate"
    />
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      minDate: new Date('2021/10/01'),
    }
  },
  mounted() {},
  created() {},
  methods: {
    sureDate(data) {
      //   var date = data.toString().split(' ').slice(0, 4)
      
      this.$emit('dateTiem', data)
    },
  },
}
</script>

<style lang="less" scoped>
.date-box {
  /deep/ .van-calendar__header-subtitle {
    background-color: #008577;
    color: #fff;
  }
  /deep/ .van-calendar__header-title {
    background-color: #008577;
    color: #fff;
  }
}
</style>
